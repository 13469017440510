/* Style pour le header */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

#header_conteneur{
	position: fixed;
	z-index: 90000;
	left: 50%;
}

.header {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 85px;
	width: 1100px;
	z-index: 100;
	position: fixed;
	background: #fff;
	left: 50%;
	top: 30px;
	border-radius: 10px;
	-webkit-transform: translate(-50%);
	-ms-transform: translate(-50%);
	transform: translate(-50%);
	-webkit-transition: all .5s linear;
	-o-transition: all .5s linear;
	transition: all .5s linear;
	-webkit-box-shadow: 0px 10px 13px -7px #000000, 0px 6px 0px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 10px 13px -7px #000000, 0px 6px 0px 0px rgba(0, 0, 0, 0.1);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.header * {
	font-family: "Lato";
}
.header a {
	color: inherit;
	text-decoration: none;
}
#header_liens_moins,
#header_liens_moins_mairie{
	display: none
}

.header_liens > a{
	height: 100%;
	width: 100%;
	text-align: center;
	-ms-flex-line-pack: center;
	align-content: center;
}

.header.headerAuDessus {
	background: black;
	top: 0;
	color: #fff;
}
.header.headerAuDessus .logo_texte {
	display: block;
	height: 50px;
}
.header.headerAuDessus .logo_texte img {
	height: 50px;
	width: 50px;
}
#logoResp{
	height: 50px;
}

.header_petits_ecrans{
	display: none;
}

.header_logo_conteneur{
	width: 10%;
	margin: 0 0 0 20px;
}

.logo_img {
	display: block;
	height: 70px;
	margin-left: 20px;
}
.logo_img img {
	height: 100%;
	width: auto;
}

.header_navigation_conteneur{
	width: 80%;
}

.header_navigation{
	width: 100%;
}

.header_liens_conteneur{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}

.header_liens{
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	list-style-type: none;
	font-weight: bold;
	font-size: 16px;
	-webkit-transition: all .4s linear;
	-o-transition: all .4s linear;
	transition: all .4s linear;
	height: 90px;
	width: 150px;
}

.header_liens:hover{
	color: #53B5F5;
}

.header_liens:hover .header_sous_liens_conteneur {
	opacity: 1;
	top: 100%;
}

.header_sous_liens_conteneur{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	top: -500%;
	background: #53B5F5;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 160px;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	padding: 5px 0 0 0;
	opacity: 0;
	-webkit-transition: opacity 0.5s, top 0.5s;
	-o-transition: opacity 0.5s, top 0.5s;
	transition: opacity 0.5s, top 0.5s;
}

.header_sous_liens {
	list-style-type: none;
	display: inline-block;
	height: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: background 0.5s;
	-o-transition: background 0.5s;
	transition: background 0.5s;
	width: 100%;
}
.header_sous_liens a {
	margin: 0 0 0 15px;
	color: #fff;
	font-weight: normal;
	text-decoration: none;
	height: 40px;
	width: 100%;
	-ms-flex-line-pack: center;
	align-content: center;
}

.header_sous_liens:hover {
	background: #fff;
}
.header_sous_liens:hover a {
	color: #53B5F5;
}

#lien_demarche{
	display: block;
	height: 45px;
	width: 180px;
	background: #53B5F5;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	font-size: 18px;
	font-family: 'Varela Round';
	font-weight: normal;
	border-radius: 10px;
	border: 1px solid #53B5F5;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
	margin: 0 20px 0 0;
}

#lien_demarche:hover{
	background: transparent;
	color: #53B5F5;
}