footer{
    width: 100%;
    height: 260px;
    background: #F3F3F3;
}
.footer_contenu{
    width: 70%;
    height: 100%;
    margin: 0 auto;
}

.footer_redirections {
    height: 170px;
    text-align: center;
    -ms-flex-line-pack: center;
    align-content: center;
}
.footer_redirections div {
    height: 50%;
    text-align: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

.footer_link {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -ms-flex-line-pack: center;
    align-content: center;
    height: 45px;
    width: 260px;
    text-decoration: none;
    background-color: #000000;
    color: #e8e8e8;
    font-size: 16px;
    margin: 20px 20px 0 20px;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 0px 15px -7px rgba(0, 0, 0, 0.28);
    box-shadow: 0px 0px 15px -7px rgba(0, 0, 0, 0.28);
}
.footer_link:hover {
    -webkit-box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.28);
    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.28);
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
}
.footer_border{
    display: block;
    margin: 30px 0 0 0;
    width: 100%;
    background: #B5B5B5;
    height: 1px;
}

.footer_liens {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 50px;
}
.footer_liens a {
    color: #B5B5B5;
    font-weight: bold;
    font-family: "Space Grotesk";
    text-decoration: none;
}
.footer_liens a:hover {
    border-bottom: 2px solid #B5B5B5;
}

.footer_liens span {
    display: inline-block;
    width: 2px;
    height: 20px;
    background: #B5B5B5;
    margin: 0 20px;
}

/*
Responsive
 */
@media screen and (max-width: 1400px){
    .footer_contenu {
        width: 80%;
    }
}
@media screen and (max-width: 1200px){
    footer, .footer_redirections{
        height: auto;
        padding-top: 20px;
    }
    .footer_link{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 20px auto;
    }
    .footer_redirections div {
        margin: 0;
        -ms-flex-line-pack: normal;
        align-content: normal;
    }
}

@media screen and (max-width: 700px){
    .footer_contenu {
        width: 95%;
    }
}

@media screen and (max-width: 550px){
    .footer_liens {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
        padding: 20px 0;
    }
    .footer_liens span {
        width: 200px;
        height: 2px;
        margin: 10px 0;
    }
}