/* Style responsive pour le header */

@media screen and (max-width: 1750px){
	header{
		-webkit-transform: scale(0.85);
		-ms-transform: scale(0.85);
		transform: scale(0.85)
	}
}

@media screen and (max-width: 1200px){
	.header{
		width: 950px;
	}
	.header_liens {
		font-size: 14px;
	}
	#lien_demarche {
		text-decoration: none;
		height: 45px;
		width: 170px;
		font-size: 14px;
	}
}

/* Affiche du header poiur petits écrans */
@media screen and (max-width: 950px){
	#header_conteneur{
		position: static;
		left: 0;
	}
	header{
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1)
	}
	#header_logo_croix, #header_liens_moins, #header_liens_moins_mairie{
		display: none;
	}
	.header{
		display: none;
	}

	.logo_texte{
		margin: 0 0 0 40px;
		color: #fff;
	}

	.header_petits_ecrans{
		display: block;
	}

	.enfant_header_petit_ecran{
		background-color: #53B5F5;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
		margin: 0 auto 0 auto;
		height: 80px;
		color: #fff;
	}
	.header_logo_menu{
		font-size: 30px;
		cursor: pointer;
		margin: 0 40px 0 0;
	}
	.header_navigation{
		width: 100%;
		margin: auto;
		display: none;
		padding: 0;
	}
	.header_navigation_conteneur{
		width: 100%;
		margin: 0 auto;
	}
	.header_liens_conteneur{
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		margin:  0 auto 0 auto;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-margin-before: 0;
		margin-block-start: 0;
		-webkit-margin-after: 0;
		margin-block-end: 0;
		-webkit-margin-start: 0px;
		margin-inline-start: 0px;
		-webkit-margin-end: 0px;
		margin-inline-end: 0px;
		-webkit-padding-start: 0;
		padding-inline-start: 0;
		-webkit-transition: height 1s linear;
		-o-transition: height 1s linear;
		transition: height 1s linear;
	}
	#header_conteneur li, .header_liens {
		width: 100%;
		border-bottom: 1px solid #D0D0D0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		height: 50px;
		z-index: 5;
		color: inherit;
		text-decoration: none;
	}
	#header_conteneur li p, .header_liens p{
		height: auto;
		width: auto;
		text-align: left;
		margin: 0 0 0 50px;
		font-family: "Space Grotesk";
		color: inherit;
		text-decoration: none;
	}

	#header_conteneur li span, .header_liens span{
		height: 100%;
		margin: 0 0 0 0;
		text-align: center;
		-ms-flex-line-pack: center;
		align-content: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: 100px;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.header_sous_liens_conteneur {
		display: none;
		position: static;
		opacity: 1;
		top: 20%;
		width: 100%;
		width: 100%;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-transition: position 1s linear, -webkit-transform 1s linear;
		transition: position 1s linear, -webkit-transform 1s linear;
		-o-transition: transform 1s linear, position 1s linear;
		transition: transform 1s linear, position 1s linear;
		transition: transform 1s linear, position 1s linear, -webkit-transform 1s linear;
		z-index: 1;
	}

	.header_sous_liens_conteneur .header_sous_liens {
		border-bottom: 1px solid #D0D0D0;
	}

	.header_sous_liens_conteneur a {
		margin: 0 0 0 50px;
		font-family: "Space Grotesk";
		font-size: 14px;
	}

	.header_sous_liens_conteneur span {
		margin: 0 50px 0 0;
	}

	#lien_demarche {
		margin: 20px auto 20px auto;
	}
	#header_liens_moins,
	#header_liens_moins_mairie,
	#header_conteneur li #header_liens_moins,
	#header_conteneur li #header_liens_moins_mairie{
		display: none
	}

}